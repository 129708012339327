import {Helmet} from "react-helmet";
import {Col, Container, Row} from "react-bootstrap";
import homeImage from "./images/homepageimage.webp";
import ImageGrid from "./imageGrid";
import ImageGrid2 from "./imageGrid2";
import ContactForm from "../../components/contactForm";
import {AddressKarlsruhe, AddressEttlingen, Kontakt} from "../../components/footer";
import Herobanner from "../../components/herobanner";

export default function Home() {
    return (
        <>
            <Helmet>
                <title>Karcher Tragwerk</title>
            </Helmet>
          <Herobanner image={homeImage} headline="Ihr Statiker in Ettlingen, Karlsruhe und Umgebung"/>

            <Container xs="12" id="home" className="container-md mb-5">
                <Row className="mb-4">
                    <Col>
                        <h2 className="text-primary"> Herzlich willkommen</h2>
                        <div>
                            <p className="text-primary"><b>Das Ingenieurbüro Karcher Tragwerk wurde 2008 von Dipl.-Ing.
                                (FH) Michael Karcher in
                                Ettlingen, bei Karlsruhe, gegründet. Nach seiner Ausbildung zum Bauzeichner und Studium
                                an
                                der Hochschule für Technik in Karlsruhe arbeitete Herr Karcher mehrere Jahre als
                                Tragwerksplaner in verschiedenen Ingenieurbüros.</b></p>
                            <p>Entwicklung optimaler Tragwerke, im Bereich Hoch-, Gewerbe- und Industriebau unter
                                Berücksichtigung wirtschaftlicher, statischer und architektonischer Gesichtspunkte, ist
                                unser Bestreben.</p>
                            <p>Wir sind an den Standorten Ettlingen und Karlsruhe vertreten.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <div>
                            <div><b>Aktuelle
                                Projekte:</b><br/></div>
                            <div>
                                <ul>
                                    <li>Erweiterung Gemeindegärtnerei Waldbronn<br/>– Neubau Lagerhalle mit Nutzflächen
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li>Produktionshalle für Großgärtnerei in Lustadt<br/>– Zweischiffige Stahlhalle
                                        (2000
                                        qm) mit Fachwerkbindern
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li>Kunsthalle Mannheim<br/>– Stahlunterkonstruktion für Metallgewebefasse
                                        (Stabwerks-
                                        und FE-Berechnungen)
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li>Neubau Autohaus in Ettlingen<br/>– 2-stöckiger Massivtrakt mit angeschlossener
                                        Stahlhalle
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li>Neubau Autohaus in Karlsruhe<br/>– Stahlhalle mit Zwischenebenen für Bürotrakt
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6" className="d-flex align-items-center justify-content-center">
                        <ImageGrid/>
                    </Col>
                    <Col xs="12" className="my-5">
                        <ImageGrid2/>
                    </Col>
                    <Col xs="12" className="text-center text-md-start pb-2">
                        <h4 className="text-primary">Nehmen Sie Kontakt auf</h4>
                    </Col>
                    <Col xs="12" md="4">
                        <Container>
                            <Row>
                                <Col xs="6" md="12">
                                    <AddressKarlsruhe/><br/> </Col>
                                <Col xs="6" md="12">
                                    <AddressEttlingen/><br/></Col>
                                <Col xs="12" >
                                    <Kontakt/>
                                </Col>
                            </Row>
                        </Container>

                    </Col>
                    <Col xs="12" md="8">
                        <ContactForm showTitle={false}/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}