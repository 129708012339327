import image1 from './images/karcher1.webp';
import image2 from './images/karcher2.webp';
import image3 from './images/karcher3.webp';
import image4 from './images/karcher4.webp';
import {Col, Image, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function ImageGrid2() {
    const images = [
        {
            url: image1,
            alt: "Stockimage Tragwerk",
            text: "Tragwerksplanung"
        },
        {
            url: image2,
            alt: "Stockimage Baugerüst",
            text: "Sanierung"
        }, {
            url: image3,
            alt: "Stockimage Baustelle",
            text: "Bauleitung"
        }, {
            url: image4,
            alt: "Stockimage Planung",
            text: "Beratung für Bauherrn, Planer..."
        },
    ]

    // Styled in custom-theme.scss
    const imageCols = images.map((element, index) =>
        <Col key={index} xs={12} md={6} className="position-relative">
            <Link to={"/leistungen"} className="image-link d-inline-block">
                <Image fluid src={element.url} alt={element.alt} className="d-inline-block"/>
                <div className="position-absolute">
                    <h5 className="text-white">{element.text}</h5>
                </div>
            </Link>
        </Col>
    )
    return (
        <Container>
            <Row className="gx-2 gy-2 text-center">
                {imageCols}
            </Row>
        </Container>
    )
}