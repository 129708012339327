import {Outlet} from "react-router";
import Header from "../components/header";
import Footer from "../components/footer";
import {Helmet} from "react-helmet";
export default function Root() {
    return (<>
            <Header/>
              <Helmet>
                <title>Karcher Tragwerk</title>
                <meta name="keywords" content="Karcher Tragwerk, Ingenieurbüro,Bauwesen,Stellenangebote, Leistungen,
Tragwerksplanung, Planung, Ingenieurbau, Gewerbebau,Gewerbe, Bau,
Lagertechnik, Technik, Industriebau, Industrie, Sanierung, Umbau ,Bauüberwachung, Fachbauleitung,Fach, Leitung, Beratung, Bauherren, Unternehmen, Referenzen, "/>
                <meta name="description" content="Karcher Tragwerk
Ingenieurbüro für Bauwesen
Das Ingenieurbüro Karcher Tragwerk wurde 2008 von Dipl.-Ing. (FH) Michael Karcher in Ettlingen, bei Karlsruhe, gegründet. Nach seiner Ausbildung zum Bauzeichner und Studium an der Hochschule für Technik in Karlsruhe arbeitete Herr Karcher mehrere Jahre als Tragwerksplaner in verschiedenen Ingenieurbüros."/>
            </Helmet>
                <Outlet/>
            <Footer/>
        </>
    );
}