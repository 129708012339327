import {Col, Container, Image, Row} from "react-bootstrap";
import image1 from './images/grid01.jpg';
import image2 from './images/grid02.jpg';
import image3 from './images/grid03.jpg';
import image4 from './images/grid04.jpg';

export default function ImageGrid() {
    return (
            <Container fluid className="p-2">
                <Row className="gx-2 gy-2 text-center">
                    <Col xs={6}><Image fluid src={image1} alt="Siemens Bauphase 4a"/></Col>
                    <Col xs={6}><Image fluid src={image2} alt="Carport"/></Col>
                    <Col xs={6}><Image fluid src={image3} alt="Halle RStab Italien"/></Col>
                    <Col xs={6}><Image fluid src={image4} alt="Bühne"/></Col>
                </Row>
            </Container>
    )
}