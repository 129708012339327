import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import {createRef, useState} from "react";
import {Button, Col, FloatingLabel, Form, Row} from "react-bootstrap";
import {EnvelopeAtFill, EnvelopeCheckFill, EnvelopeDash} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

export default function ContactForm(props) {
    const [validated, setValidated] = useState(false);
    const [buttonState, setButtonState] = useState(0);
    const [allowRecaptcha, setAllowRecaptcha] = useState(false);
    const recaptchaRef = createRef();
    const [form, setForm] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    })

    const handleChange = (event) => {
        setForm({...form, [event.target.name]: event.target.value});
    }
    const handelConsent= (event) => {
          if (event.target.checked){
            console.log("recaptcha build in")
        }
        setAllowRecaptcha(event.target.checked)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            setButtonState(1);
            await sendEmail()
        }
    }

    const sendEmail = async () => {
        const token = await recaptchaRef.current.executeAsync();
        const params = {
            from_name: form.name,
            reply_to: form.email,
            message: form.message,
            'g-recaptcha-response': token,
        };
        emailjs.send(
            "michi_emailjs",
            "template_michi",
            params,
            "AE93_tJzSm2RLKOHX",
        )
            .then(({status}) => {
                setButtonState(2);
            }, (err) => {
                setButtonState(3);
                console.log("Email ERROR", err);
            });
    };
    let buttonInnerHtml
    let buttonVariant
    let buttonDisabled
    switch (buttonState) {
        case 1:
            buttonDisabled = true
            buttonVariant = "primary"
            buttonInnerHtml = <><span className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"></span> senden ...</>
            break;
        case 2:
            buttonDisabled = true
            buttonInnerHtml = <span> <EnvelopeCheckFill size={24}/>&nbsp;&nbsp;Gesendet</span>;
            buttonVariant = "success"
            break;
        case 3:
            buttonDisabled = false
            buttonInnerHtml = <span> <EnvelopeDash size={24}/>&nbsp;&nbsp;Fehler</span>;
            buttonVariant = "danger"
            break;
        default:
            buttonDisabled = false
            buttonVariant = "primary"
            buttonInnerHtml = <span> <EnvelopeAtFill size={24}/>&nbsp;&nbsp;Senden</span>;
            break;
    }
    return (
        <> {allowRecaptcha &&
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LfsdgskAAAAAJeMglA5RCzeCkDB7GRVhSOsuA37"
            />
        }
            <Form className="container form-floating" noValidate validated={validated}
                  onSubmit={handleSubmit}>
                <Row className="gy-2 gx-2">
                    {props.showTitle && <Col xs={12}>
                        <h3 className="text-primary mb-0">Kontaktformular</h3>
                    </Col>}

                    <Col xs={4}>
                        <FloatingLabel controlId="floatingInput" label="Email">
                            <Form.Control required type="email" name="email" value={form.email}
                                          onChange={handleChange}/>
                        </FloatingLabel>
                    </Col>
                    <Col xs={4}>
                        <FloatingLabel controlId="floatingInput" label="Name">
                            <Form.Control required type="text" name="name" value={form.name} onChange={handleChange}/>
                        </FloatingLabel>
                    </Col>
                    <Col xs={4}>
                        <FloatingLabel controlId="floatingInput" label="Telefon">
                            <Form.Control type="text" name="phone" value={form.phone} onChange={handleChange}/>
                        </FloatingLabel>
                    </Col>
                    <Col xs={12}>
                        <FloatingLabel controlId="floatingTextarea" label="Nachricht">
                            <Form.Control required as="textarea" name="message" onChange={handleChange}
                                          value={form.message} style={{height: '150px'}}/>
                        </FloatingLabel>
                    </Col>
                    <Col xs={12}>
                        <Form.Group className="mb-3 " controlId="formBasicCheckbox">
                            <Form.Check size="sm" type="checkbox">
                                <Form.Check.Input required className="small" type="checkbox" checked={allowRecaptcha}
                                                  onChange={handelConsent} name="consent"/>

                                <Form.Check.Label className="small">Ich habe die <Link to="/datenschutz" target="_blank"
                                                                                       rel="noreferrer">Datenschutzerklärung</Link> zur
                                    Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung
                                    meiner Anfrage elektronisch erhoben und gespeichert werden.</Form.Check.Label>

                            </Form.Check>
                        </Form.Group>
                    </Col>

                </Row>
                <Row className="justify-content-end">
                    <Col>
                        <Button variant={buttonVariant} disabled={buttonDisabled} className="float-end send-btn"
                                type="submit">
                            {buttonInnerHtml}
                        </Button>
                    </Col>
                </Row>
            </Form>

        </>
    );
}