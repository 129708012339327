import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Col, Row} from "react-bootstrap";
import {useLocation} from "react-router-dom"
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useState} from "react";
import logo from '../images/logo_footer.png'; // Tell webpack this JS file uses this image
import { HashLink as Link } from 'react-router-hash-link';


const logo_style = {maxWidth: 960, width: '100%', height: 'auto', margin: 'auto'}
export default function Header() {
    const [menuOpen, setMenuOpen] = useState(false)
    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }
    const handleClose = () => setMenuOpen(false)
    const location = useLocation()
    return (
        <Navbar expand="lg" className="border-bottom">
            <Container className="mb-2 mb-lg-0">
                <Row style={{width: "100%"}}>
                    <Col xs="10" lg="12" >
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Link to='/'>
                                <img
                                    src={logo}
                                    width="960"
                                    height="122"
                                    alt="Karcher Tragwerk logo"
                                    style={logo_style}
                                />
                            </Link>
                        </div>
                    </Col>
                    <Col xs={2} lg={12} style={{alignItems: "center"}}>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} onClick={toggleMenu}/>
                        {/*<Navbar.Toggle aria-controls={`basic-navbar-nav`}/>*/}
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-lg`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                            placement="end"
                            restoreFocus={false}
                            show={menuOpen}
                            onHide={handleClose}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                    Menu
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="my-2">
                                <Nav className="m-auto" activeKey={location.pathname}>
                                    <Nav.Item>
                                        <Nav.Link as={Link} onClick={handleClose} href="/" to="/">Home</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link as={Link} onClick={handleClose} href="/leistungen"
                                                  to="/leistungen">Leistungen</Nav.Link>
                                    </Nav.Item>
                                    <NavDropdown title="Referenzen" id="offcanvasNavbarDropdown-expand-lg">
                                        <NavDropdown.Item onClick={handleClose} as={Link} to="/referenzen"
                                                          href="/referenzen#industriebau">Alle</NavDropdown.Item>
                                        <NavDropdown.Item onClick={handleClose} as={Link} to="/referenzen#industriebau"
                                                          href="/referenzen#industriebau">Industriebau</NavDropdown.Item>
                                        <NavDropdown.Item onClick={handleClose} as={Link} to="/referenzen#lagertechnik"
                                                          href="/referenzen#lagertechnik">Lagertechnik /
                                            Bühnen</NavDropdown.Item>
                                        <NavDropdown.Item onClick={handleClose} as={Link} to="/referenzen#wohnungsbau"
                                                          href="/referenzen#wohnungsbau">Wohnungsbau /
                                            Gewerbebau</NavDropdown.Item>
                                        <NavDropdown.Item onClick={handleClose} as={Link} to="/referenzen#verschiedenes"
                                                          href="/referenzen#verschiedenes">Verschiedenes</NavDropdown.Item>
                                    </NavDropdown>
                                    {/*<Nav.Item>*/}
                                    {/*    <Nav.Link as={Link} onClick={handleClose} href="/stellenangebote"*/}
                                    {/*              to="/stellenangebote">Stellenangebote</Nav.Link>*/}
                                    {/*</Nav.Item>*/}
                                    <Nav.Item>
                                        <Nav.Link as={Link} onClick={handleClose} href="/kontakt"
                                                  to="/kontakt">Kontakt</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>

                    </Col>
                </Row>
            </Container>
        </Navbar>
    )
        ;
}