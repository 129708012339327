import React, {lazy} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './custom-theme.scss';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import Root from "./routes/root";
import Home from "./routes/home/";
import ErrorPage from "./error-page";


const Kontakt = lazy(() => import( "./routes/kontakt"))

const Leistungen = lazy(() => import( "./routes/leistungen"))
const Referenzen = lazy(() => import( "./routes/referenzen"))
// import Stellenangebote from "./routes/stellenangebote";
const Datenschutzerklaerung = lazy(() => import("./routes/datenschutzerklaerung"))
const Impressum = lazy(() => import( "./routes/impressum"))

const loading = "Loading...";
const browserRouter = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage/>,
        children: [
            {path: "kontakt", element: <React.Suspense fallback={<>{loading}</>}><Kontakt/> </React.Suspense>},
            {path: "/", element: <Home/>},
            {path: "leistungen", element: <React.Suspense fallback={<>{loading}</>}><Leistungen/> </React.Suspense>},
            {path: "referenzen", element: <React.Suspense fallback={<>{loading}</>}><Referenzen/> </React.Suspense>},
            // {path: "stellenangebote", element: <Stellenangebote/>},
            {
                path: "datenschutz",
                element: <React.Suspense fallback={<>{loading}</>}><Datenschutzerklaerung/> </React.Suspense>
            },
            {path: "impressum", element: <React.Suspense fallback={<>{loading}</>}><Impressum/> </React.Suspense>},
            // {path: "*", element: <Home/>},
        ]
    },
], {basename: "/"});

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={browserRouter}/>
    </React.StrictMode>
);
