import {Col, Container, Row} from "react-bootstrap";
import logo from '../images/logo_footer.png';
import {Link} from "react-router-dom";

const logo_style = {margin: 'auto'}

export function AddressKarlsruhe() {
    return (
        <div>
            <b>Karlsruhe</b>
            <div className="address">
                <span>Hans-Sachs-Str. 1</span><br/>
                <span>76133 Karlsruhe</span>
            </div>
        </div>
    )
}

export function AddressEttlingen() {
    return (
        <div>
            <b>Ettlingen</b>
            <div className="address">
                <span>Wilhelmstr. 21</span><br/>
                <span>76275 Ettlingen</span>
            </div>
        </div>
    )
}

export function Kontakt() {
    return (
        <div>
            <address>
                <b>Email:</b> <a href="mailto:info@karchertragwerk.de">info@karchertragwerk.de</a><br/>
                <b>Tel.:</b> <a href="tel:+4972433754123">07243 / 3754-123</a><br/>

            </address>
        </div>
    )
}

export default function Footer() {
    return (<footer className="border-top ">
        <Container className="">
            <Row className="justify-content-center pt-4 gy-4 gx-md-4 mb-3">
                <Col xs={12} lg="auto" className="d-flex justify-content-center">
                    <div>
                        <img
                            src={logo}
                            width="225"
                            alt="Karcher Tragwerk logo"
                            style={logo_style}
                            className="m-auto"
                        />

                    </div>
                </Col>
                <Col xs={12} md={6} lg="auto" className="d-flex justify-content-center justify-content-md-end">
                    <div>
                        <h5 className="text-center text-lg-start text-primary mb-1 fw-bold">Standorte</h5>
                        <div className="d-flex flex-shrink-0 gap-3">
                            <AddressEttlingen/>
                            <AddressKarlsruhe/>

                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} lg="auto" className="d-flex justify-content-center justify-content-md-start">
                    <div className="text-center text-lg-start">
                        <h5 className="text-primary mb-1 fw-bold">Kontakt</h5>
                        <Kontakt/>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className="bg-primary text-white">
            <Row>
                <Col className="text-center py-3">
                    <Link to="/impressum" className="text-white">Impressum</Link> | <Link to="/kontakt"
                                                                                          className="text-white">Kontakt</Link> | <Link
                    className="text-white" to={"/datenschutz"}>Datenschutzerklärung</Link>
                </Col>
            </Row>
        </Container>
    </footer>)
}
