import {Container, Row} from "react-bootstrap";

export default function Herobanner(props) {
    const image = props.image
    return (
            <Container fluid className="text-center mb-5" style={{position: "relative"}}>
                <Row>
                    <div className={"hero-image " +props.size} style={{
                        backgroundImage: 'url(' + image + ')'
                    }}></div>
                    <h1 className="hero-text text-white display-5 fw-bold"
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "80%"
                        }}> {props.headline}</h1>
                </Row>
            </Container>
    )
}
Herobanner.defaultProps = {
   size: "",
};